/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    table: "table",
    thead: "thead",
    tr: "tr",
    th: "th",
    tbody: "tbody",
    td: "td",
    code: "code",
    h2: "h2",
    pre: "pre"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "text-alignment",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#text-alignment",
    "aria-label": "text alignment permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Text Alignment"), "\n", React.createElement(_components.p, null, "Utilities for controlling the alignment of text."), "\n", React.createElement("carbon-ad"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, null, "React props"), React.createElement(_components.th, null, "CSS Properties"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.code, null, "textAlign={alignment}")), React.createElement(_components.td, null, React.createElement(_components.code, null, "text-align: {alignment};"))))), "\n", React.createElement(_components.h2, {
    id: "usage",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#usage",
    "aria-label": "usage permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Usage"), "\n", React.createElement(_components.p, null, "Control the text alignment of an element using the ", React.createElement(_components.code, null, "textAlign=\"left\""), ", ", React.createElement(_components.code, null, "textAlign=\"center\""), ", ", React.createElement(_components.code, null, "textAlign=\"right\""), ", and ", React.createElement(_components.code, null, "textAlign=\"justify\""), " utilities."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=cool-gray"
  }, "<>\n  <template preview>\n    <x.div spaceY={8}>\n      {[\n        ['left', 'purple'],\n        ['center', 'rose'],\n        ['right', 'emerald'],\n        ['justify', 'amber'],\n      ].map(([textAlign, color]) => (\n        <x.dl key={textAlign} color={`${color}-700`} overflow=\"hidden\">\n          <x.dt\n            w={16}\n            flexShrink={0}\n            fontSize=\"sm\"\n            opacity={0.8}\n            fontFamily=\"mono\"\n            mb={1}\n          >\n            textAlign={textAlign}\n          </x.dt>\n          <x.dd fontSize=\"xl\" m={0}>\n            <x.div\n              textAlign={textAlign}\n              fontFamily=\"flow\"\n              fontSize=\"3xl\"\n              lineHeight={0.8}\n              color={`${color}-700`}\n            >\n              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nobis\n              fugit, enim molestiae praesentium eveniet, recusandae et error\n              beatae facilis ex harum consequuntur, quia pariatur non. Doloribus\n              illo, ullam blanditiis ab.\n            </x.div>\n          </x.dd>\n        </x.dl>\n      ))}\n    </x.div>\n  </template>\n  <x.p textAlign=\"left\">Lorem ipsum ...</x.p>\n  <x.p textAlign=\"center\">Lorem ipsum ...</x.p>\n  <x.p textAlign=\"right\">Lorem ipsum ...</x.p>\n  <x.p textAlign=\"justify\">Lorem ipsum ...</x.p>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "responsive",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#responsive",
    "aria-label": "responsive permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Responsive"), "\n", React.createElement(_components.p, null, "To control the space between elements at a specific breakpoint, use responsive object notation. For example, adding the property ", React.createElement(_components.code, null, "textAlign={{ md: \"justify\" }}"), " to an element would apply the ", React.createElement(_components.code, null, "textAlign=\"justify\""), " utility at medium screen sizes and above."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<x.div textAlign={{ md: 'justify' }}>{/* ... */}</x.div>\n")), "\n", React.createElement(_components.p, null, "For more information about xstyled's responsive design features, check out ", React.createElement(_components.a, {
    href: "/docs/responsive-design/"
  }, "Responsive Design"), " documentation."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
